export const bytesToSize = (
  bytes: number | null | undefined,
  unit: string | "B" | "KB" | "MB" | "GB" | "TB" = "GB",
) => {
  if (!bytes) return 0;
  const units = ["B", "KB", "MB", "GB", "TB"];

  const index = units.indexOf(unit);
  if (index < 0) {
    throw new Error("Invalid unit");
  }

  const bytesPerUnit = Math.pow(1024, index);
  return bytes / bytesPerUnit;
};

export const sizeTobytes = (
  size: number | null | undefined,
  unit: string | "B" | "KB" | "MB" | "GB" | "TB" = "GB",
) => {
  if (!size) return 0;
  const units = ["B", "KB", "MB", "GB", "TB"];

  const index = units.indexOf(unit);
  if (index < 0) {
    throw new Error("Invalid unit");
  }

  const bytesPerUnit = Math.pow(1024, index);
  return size * bytesPerUnit;
};

export const joinPaths = (...paths: string[]) => {
  return paths
    .map((path) => path.replace(/\\/g, "/"))
    .map((path, index) => {
      if (index === 0) {
        return path.replace(/\/$/, "");
      } else {
        return path.replace(/^\/|\/$/g, "");
      }
    })
    .join("/");
};

export const appVersionComparison = (version: string | number) => {
  if (!window?.$platform?.AppInfo) return false;

  let appVersion;
  if (typeof version === "string") appVersion = parseInt(version.replace(/\./g, ""));
  else appVersion = version;

  return parseInt(window.$platform.AppInfo.appVersion.replace(/\./g, "")) >= appVersion;
};

export const appVersionComparisonPlus = (
  winVersion: string | number,
  macVersion: string | number,
) => {
  if (!window?.$platform?.AppInfo) return false;

  if (window.$platform.os == "Win") return appVersionComparison(winVersion);
  else return appVersionComparison(macVersion);
};

export const sanitizeFileName = (fileName: string) => {
  const invalidChars = /[\\/:*?"<>|]/g;
  return fileName.replace(invalidChars, "_");
};

export const setupLocalStorageListener = () => {
  const { setItem, removeItem } = localStorage.__proto__;

  localStorage.__proto__.setItem = function (key: string, value: string) {
    const oldValue = localStorage.getItem(key);
    if (oldValue !== value) {
      const event = new CustomEvent("localStorageChange", {
        detail: { key, oldValue, newValue: value, action: "setItem" },
      });

      window.dispatchEvent(event);
      setItem.call(this, key, value);
    }
  };

  localStorage.__proto__.removeItem = function (key: string) {
    const event = new CustomEvent("localStorageChange", {
      detail: { key, oldValue: localStorage.getItem(key), newValue: null, action: "removeItem" },
    });
    window.dispatchEvent(event);
    removeItem.call(this, key);
  };
};
