import React from "react";

import { Button, SvgIcon, clsx } from "@gemlightbox/core-kit";
import { ReactComponent as CloseSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import cameraLaptopSrc from "src/external-ts/assets/images/camera/verify-camera-connection-laptop-2.png";
import { ReactComponent as UsbPortSVG } from "src/external-ts/assets/images/camera/usb-port-icon.svg";
import { ReactComponent as PowerSupplySVG } from "src/external-ts/assets/images/camera/power-supply-icon.svg";
import { ReactComponent as RestartSVG } from "src/external-ts/assets/images/camera/restart-icon.svg";

import styles from "./camera-troubleshooting.module.css";
import { useStores } from "src/hooks";

type VerifyGemcamConnectionProps = {
  onClose: VoidFunction;
};

export const VerifyGemcamConnection: React.FC<VerifyGemcamConnectionProps> = ({ onClose }) => {
  const { localeStore } = useStores();

  return (
    <div className={clsx(styles.cameraTroubleshootingGemcamContainer, styles.connectionContainer)}>
      <Button className={styles.closeButton} appearance="secondaryGhost" onClick={onClose}>
        <SvgIcon icon={CloseSVG} />
      </Button>
      <div className={styles.cameraTroubleshootingBlockContent}>
        <img className={styles.cameraTroubleshootingConnectionBanner} src={cameraLaptopSrc} />
        <div className={clsx(styles.cameraTroubleshootingBlockTitle, styles.pdV24)}>
          <span>
            {localeStore.t('camera["camera-troubleshooting"]["gemcam-disconnected"].title')}
          </span>
        </div>
        <div className={clsx(styles.blockTitle)}>
          <div className={styles.blockSubtitle}>
            <div className={styles.icon}>
              <SvgIcon icon={UsbPortSVG} size={28} />
            </div>
            <span>
              {localeStore.t('camera["camera-troubleshooting"]["gemcam-disconnected"].step1')}
            </span>
          </div>
          <div className={styles.blockSubtitle}>
            <div className={styles.icon}>
              <SvgIcon icon={PowerSupplySVG} size={28} />
            </div>
            <span>
              {localeStore.t('camera["camera-troubleshooting"]["gemcam-disconnected"].step2')}
            </span>
          </div>
          <div className={styles.blockSubtitle}>
            <div className={styles.icon}>
              <SvgIcon icon={RestartSVG} size={28} />
            </div>
            <span>
              {localeStore.t('camera["camera-troubleshooting"]["gemcam-disconnected"].step3')}
            </span>
          </div>
        </div>
        <Button
          appearance="unset"
          className={styles.contactUsBtn}
          onClick={() =>
            window.open("https://picupmedia.com/contact-us/?contact-support", "_blank")
          }
        >
          <span
            dangerouslySetInnerHTML={{
              __html: localeStore.t(
                'camera["camera-troubleshooting"]["gemcam-disconnected"]["contact-us"]',
              ),
            }}
          />
        </Button>
      </div>
    </div>
  );
};
