import React from "react";
import { Routes, Route as RouterRoute } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  useDidUpdate,
  Loader,
  download,
  NotificationText,
  useDidMount,
} from "@gemlightbox/core-kit";

import WSConnection from "src/common/socket";
import { WebsocketStatus } from "src/models";
import { RouteGuard, routes } from "src/config/routes.config";
import { useStores } from "src/hooks";
import {
  pushDataLayerEvent,
  pushGemHubAppUpgrageRequiredEvent,
  pushIdentifyDataLayerEvent,
  pushUpdateContextPropertiesEnvironmentInfoEvent,
} from "src/utils";

import styles from "src/external-ts/assets/styles/app.module.css";
import { useDispatch } from "react-redux";
import { logout } from "./redux/reducers/user";
import { setupLocalStorageListener } from "./utils/common.utils";
import { MessageTypes } from "./store/user/user.store.types";
import { LOGOUT_PAGE } from "./constants";

export const App = observer(() => {
  const {
    attributesStore,
    userStore,
    categoriesStore,
    subscriptionsStore,
    subaccountsStore,
    localeStore,
    productsStore,
    toastStore,
    notificationStore,
    appCallbackStore,
  } = useStores();

  const [isloading, setIsLoading] = React.useState(false);
  const token = userStore.token;
  const dispatch = useDispatch();

  const checkUserAccess = () => !window.$platform.isCompact && userStore.isGemhubPreviewUser;

  const initializeLocalStorageListener = () => {
    if (window.$platform.isCompact && window.$platform.os === "Mac") {
      setupLocalStorageListener();
      window.addEventListener("localStorageChange", (event: Event) => {
        const customEvent = event as CustomEvent<any>;
        userStore.parentPostMessage(
          {
            action: customEvent.detail.action,
            key: customEvent.detail.key,
            value: customEvent.detail.newValue,
          },
          MessageTypes.localStorageChange,
        );
      });
    }
  };

  useDidUpdate(
    async () => {
      if (!token) return;

      const subscriptionConnection = new WSConnection("/subscription", token);
      subscriptionConnection.on("subscription/UPDATE", () => {
        userStore.loadUserMeSilently();
        subscriptionsStore.loadSubscription();
      });

      return () => subscriptionConnection.destroy();
    },
    [token],
    true,
  );

  useDidUpdate(
    () => {
      if (!token || !productsStore.exportedFileId) return;

      const productsConnection = new WSConnection("/product", token);

      productsConnection.on("product/EXPORT_STATUS", ({ payload }: any) => {
        if (payload.export_id !== productsStore.exportedFileId) return;

        if (payload.status === WebsocketStatus.COMPLETED) {
          toastStore.close();
          productsStore.clearExportedFileId();

          download(payload.link);
        }

        if (payload.status === WebsocketStatus.ERROR) {
          toastStore.close();
          productsStore.clearExportedFileId();

          notificationStore.open({
            title: localeStore.t('app["error-modal"].title'),
            message: (
              <NotificationText disableBold>
                {`${localeStore.t('app["error-modal"]["export-id"]')}: ${payload.export_id}`}
                <br />
                {`${localeStore.t('app["error-modal"].error')}: ${payload.message}`}
              </NotificationText>
            ),
            icon: "crossRoundedFilled",
            confirmAppearance: "error",
            confirmText: localeStore.t("common.buttons.ok"),
            cancelText: "",
            onlyConfirm: true,
          });
        }
      });

      return () => productsConnection.destroy();
    },
    [token, productsStore.exportedFileId],
    true,
  );

  useDidUpdate(
    async () => {
      if (!token || location.pathname === LOGOUT_PAGE.path) {
        return;
      }
      setIsLoading(true);
      attributesStore.loadAttributesList();
      categoriesStore.loadCategoriesList();
      subaccountsStore.loadSubaccounts();
      await subscriptionsStore.loadSubscription();

      userStore.loadUserMe().then((result) => {
        setIsLoading(false);
        if (result.status === "success") {
          pushIdentifyDataLayerEvent();
        }
        if (result.status === "error" && result.error?.statusCode === 401) {
          // following ./redux/middlewares/unauhorized.middleware.js
          dispatch(logout());
        }
        if (result.status === "error" && result.error?.statusCode === 401) {
          // following ./redux/middlewares/unauhorized.middleware.js
          dispatch(logout());
        }
      });
    },
    [token],
    true,
  );

  useDidMount(() => {
    initializeLocalStorageListener();
    localeStore.setWritingDirection();
    if (!token) {
      pushIdentifyDataLayerEvent();
    }
  });

  appCallbackStore.getLifeCycleStatus((data) => {
    if (data.status === 1) {
      pushDataLayerEvent({ event: "gemhub:app:launch" });
    }
  });

  if (!window.$platform.AppInfo) {
    appCallbackStore.getEnvironmentInfo((data) => {
      window.$platform.AppInfo = data;
      pushUpdateContextPropertiesEnvironmentInfoEvent(data);
      pushGemHubAppUpgrageRequiredEvent(data);
    });
  }

  if (localeStore.initLoading || isloading) {
    appCallbackStore.webviewNotification({ type: 1 });
    return (
      <div className={styles.appContainer}>
        <Loader type="goo-loader" position="absolute" />
      </div>
    );
  }

  return (
    <div className={styles.appContainer}>
      {checkUserAccess() && (
        <div className={styles.appContainer}>
          <Loader type="goo-loader" position="absolute" />
        </div>
      )}
      <Routes>
        {routes.map((route) => (
          <RouterRoute key={route.path} path={route.path} element={<RouteGuard {...route} />} />
        ))}
      </Routes>
    </div>
  );
});
