import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { clsx, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { proFeatureTooltip } from "src/utils";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { SettingsTabsType } from "../../settings.types";

import styles from "./settings-nav-link.module.css";

export type SettingsNavLinkProps = {
  tabItem: SettingsTabsType;
};

export const SettingsNavLink: React.FC<SettingsNavLinkProps> = observer(
  ({ tabItem }: SettingsNavLinkProps) => {
    const location = useLocation();

    const targetRef = useRef<HTMLDivElement>(null);

    const { localeStore, tooltipStore, userStore } = useStores();
    const { canDNS } = useLimits();

    const tab = tabItem.item.tab;
    const path = tabItem.item.path;
    const dataCy = tabItem.item.name;

    const tabText = localeStore.t(`settings["settings-nav"]["tab-names"]["${tab}"]`);

    const isActive = location.pathname === path;

    const dnsDisabled = tab === "dns" && !canDNS;
    const validityDisabled = !userStore.isValid;
    const disabled = dnsDisabled || validityDisabled;

    const handlePointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
      if (dnsDisabled) {
        proFeatureTooltip(e.currentTarget, "custom_domain", {
          position: "left",
        });
        return;
      }

      if (validityDisabled) {
        tooltipStore.open(
          <Typography size="extraSmall">
            {localeStore.t('common.warnings["fulfill-profile"]')}{" "}
          </Typography>,
          {
            target: e.currentTarget,
            appearance: "secondary",
            position: "left",
            withAngle: true,
            onPointerLeave: () => tooltipStore.close(),
          },
        );
      }
    };

    const handlePointerLeave = () => {
      if (dnsDisabled || validityDisabled) return;
      tooltipStore.close();
    };

    if (disabled) {
      return (
        <div
          key={tab}
          className={clsx(styles.tab, { [styles.active]: isActive })}
          ref={targetRef}
          onPointerEnter={handlePointerEnter}
          onPointerLeave={handlePointerLeave}
          data-cy={dataCy}
        >
          {tabText}
        </div>
      );
    }

    return (
      <Link
        key={tab}
        className={clsx(styles.tab, { [styles.active]: isActive })}
        to={path}
        data-cy={dataCy}
      >
        {tabText}
      </Link>
    );
  },
);

export default SettingsNavLink;
