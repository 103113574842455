import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Stripe, StripeElements, PaymentIntent, StripeError } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import {
  Modal,
  MessageField,
  Button,
  Form,
  useDidUpdate,
  useBoolean,
  useCall,
  useFetch,
  Loader,
  Nullable,
  ApiRequestErrorResponse,
} from "@gemlightbox/core-kit";

import {
  postSubscription,
  putSubscriptionAdditional,
  patchSubscription,
  getCards,
  putCard,
  getUserTagManagerInfo,
  getUserTagManagerInfoCallback,
  PostSubscriptionCardData,
} from "src/api";
import { CardModel } from "src/models";
import { useStores } from "src/hooks";
import { ErrorCodes } from "src/constants";
import { pushDataLayerEvent } from "src/utils";
import {
  proSubscriptionsTypePriceMap,
  starterSubscriptionsTypePriceMap,
  additionalSubGbPriceMap,
  proPlanId,
  proV2SubscriptionsTypePriceMap,
} from "src/containers/settings/subscriptions/subscriptions.constants";
import { BillingCardItem } from "./billing-card-item";
import { stripePromise } from "./payment-modal.constants";
import { PaymentModalProps } from "./payment-modal.types";

import styles from "./payment-modal.module.css";

export const PaymentModal: React.FC<PaymentModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }: PaymentModalProps) => {
    const { subscriptionToSelect, onPaymentSuccess, addonsIds = 0, coupon, planToSelect } = options;

    const { modalsStore, subscriptionsStore, localeStore } = useStores();

    const { isFreeSubscription, planInterval, currentPlan, isProSubscriptionV2 } =
      subscriptionsStore;

    const useUserGoogleTagFetch = useFetch(getUserTagManagerInfo);

    const useCardsFetch = useFetch(getCards);
    const cardsArr = useCardsFetch.payload || [];

    const useLoadingBoolean = useBoolean(false);
    const [errorText, setErrorText] = useState("");
    const [selectedCard, setSelectedCard] = useState<Nullable<CardModel>>();

    const userDefaultCard = useMemo(() => {
      return useCardsFetch.payload?.find((card) => card.isDefault);
    }, [useCardsFetch.payload]);

    useDidUpdate(() => setSelectedCard(userDefaultCard), [userDefaultCard]);

    const handleAddCard = () => {
      modalsStore.open("AddCardModal", {
        onAddCardSuccess: useCardsFetch.refresh,
      });
    };

    const handlePaymentSuccess = () => {
      getUserTagManagerInfoCallback((response) => {
        pushDataLayerEvent({
          event: "upgrade_completed",
          event_params: {
            user_id: response.user_id,
            prev_account_type: useUserGoogleTagFetch.payload?.account_type ?? "couldn't get data",
            prev_paid_plan: useUserGoogleTagFetch.payload?.current_paid_plan ?? "couldn't get data",
            new_account_type: response?.account_type ?? "couldn't get data",
            new_paid_plan: response?.current_paid_plan ?? "couldn't get data",
            is_trial: response?.isTrial ?? "couldn't get data",
          },
        });
      });

      setClose();
      onPaymentSuccess();
    };

    const usePutCard = useCall(putCard);

    const usePostSubscription = useCall(postSubscription);
    usePostSubscription.onCallSuccess(handlePaymentSuccess);

    const usePutSubscription = useCall(putSubscriptionAdditional);
    usePutSubscription.onCallSuccess(handlePaymentSuccess);

    const usePatchSubscription = useCall(patchSubscription);
    usePatchSubscription.onCallSuccess(handlePaymentSuccess);

    const handleConfirmPayment = (
      stripe: Stripe,
      onSuccess?: (res: PaymentIntent) => void,
      onError?: (error: StripeError) => void,
    ) => {
      return (error: ApiRequestErrorResponse) => {
        const { originalError, formattedMessage } = error;
        setErrorText(formattedMessage);

        window.onbeforeunload = () => {
          if (userDefaultCard?.id) usePutCard.submit({ data: { card: userDefaultCard.id } });
        };

        if (originalError?.code === ErrorCodes.SUBSCRIPTION_REQUIRE_ACTION) {
          stripe.confirmCardPayment(originalError.payload.client_secret).then((res) => {
            window.onbeforeunload = null;

            if (res?.paymentIntent?.status === "succeeded") {
              onSuccess?.(res.paymentIntent);
            }
            if (res?.error) {
              const errorMessage = res.error.message || formattedMessage;

              getUserTagManagerInfoCallback((response) => {
                pushDataLayerEvent({
                  event: "upgrade_failed",
                  event_params: {
                    user_id: response.user_id,
                    prev_account_type:
                      useUserGoogleTagFetch.payload?.account_type ?? "couldn't get data",
                    prev_paid_plan:
                      useUserGoogleTagFetch.payload?.current_paid_plan ?? "couldn't get data",
                    new_account_type: response?.account_type ?? "couldn't get data",
                    new_paid_plan: response?.current_paid_plan ?? "couldn't get data",
                    is_trial: response?.isTrial ?? "couldn't get data",
                    source_platform: errorMessage,
                  },
                });
              });

              if (userDefaultCard?.id) usePutCard.submit({ data: { card: userDefaultCard.id } });
              setErrorText(errorMessage);
              useLoadingBoolean.setFalsy();
              onError?.(res.error);
            }
          });

          return;
        }

        getUserTagManagerInfoCallback((response) => {
          pushDataLayerEvent({
            event: "upgrade_failed",
            event_params: {
              user_id: response.user_id,
              prev_account_type: useUserGoogleTagFetch.payload?.account_type ?? "couldn't get data",
              prev_paid_plan:
                useUserGoogleTagFetch.payload?.current_paid_plan ?? "couldn't get data",
              new_account_type: response?.account_type ?? "couldn't get data",
              new_paid_plan: response?.current_paid_plan ?? "couldn't get data",
              is_trial: response?.isTrial ?? "couldn't get data",
              source_platform: formattedMessage,
            },
          });
        });

        useLoadingBoolean.setFalsy();
      };
    };

    const handleSubmit = (stripe: Nullable<Stripe>, elements: Nullable<StripeElements>) => {
      return async () => {
        const card = selectedCard?.id;

        if (!stripe || !elements || !card) return;

        setErrorText("");
        useLoadingBoolean.setTruthy();

        usePostSubscription.onCallError(
          handleConfirmPayment(stripe, handlePaymentSuccess, useLoadingBoolean.setFalsy),
        );
        usePatchSubscription.onCallError(
          handleConfirmPayment(stripe, handlePaymentSuccess, useLoadingBoolean.setFalsy),
        );

        const isOldPlan = currentPlan?.plan_id === proPlanId;
        const priceId =
          planToSelect === "pro"
            ? isOldPlan
              ? proSubscriptionsTypePriceMap[subscriptionToSelect]
              : proV2SubscriptionsTypePriceMap[subscriptionToSelect]
            : starterSubscriptionsTypePriceMap[subscriptionToSelect];

        const queryParams = `?prices=${JSON.stringify([
          [additionalSubGbPriceMap[subscriptionToSelect], addonsIds],
        ])}`;

        const data: PostSubscriptionCardData = { card };

        if (coupon?.trim()) {
          data.coupon = coupon?.trim();
        }

        // You have no subscription and you buy one ->
        if (isFreeSubscription) {
          usePostSubscription.submit({
            params: { priceId },
            data,
            queryParams,
          });

          return;
        }

        // You already have subscription and you only buy extra addons
        if (
          (planInterval === subscriptionToSelect &&
            planToSelect === currentPlan?.name.toLowerCase()) ||
          isProSubscriptionV2
        ) {
          usePutSubscription.onCallError(handleConfirmPayment(stripe, handlePaymentSuccess));

          await usePutSubscription.submit({
            data,
            queryParams: `?priceID=${additionalSubGbPriceMap[subscriptionToSelect]}&quantity=${addonsIds}`,
          });
        } else {
          // You already have subscription, and you change to another plan & addons
          if (addonsIds === 0 && planToSelect !== currentPlan?.name.toLowerCase()) {
            await putSubscriptionAdditional
              .getRequest({
                data,
                queryParams: `?priceID=${
                  additionalSubGbPriceMap[planInterval || "monthly"]
                }&quantity=${addonsIds}`,
              })
              .fetch();
          }

          await usePatchSubscription.submit({
            params: { priceId },
            data,
            queryParams,
          });
        }
      };
    };

    return (
      <Modal
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        withCross={!useLoadingBoolean.value}
        disableBackdropClose
      >
        {useCardsFetch.loading && <Loader position="absolute" withOverlay />}
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <Form
                contentClassName={styles.formContent}
                initialValues={{}}
                onSubmit={handleSubmit(stripe, elements)}
              >
                <div className={styles.modalTitle}>
                  {localeStore.t('components.business["payment-modal"].title')}
                </div>
                <MessageField
                  className={styles.modalErrorMessage}
                  message={errorText}
                  size="large"
                  hideMessage={!errorText}
                />
                <div className={styles.cardsWrapper}>
                  {cardsArr.map((card) => (
                    <BillingCardItem
                      key={card.id}
                      cardItem={card}
                      onChange={setSelectedCard}
                      checked={selectedCard?.id === card.id}
                    />
                  ))}
                  {!useCardsFetch.loading && (
                    <div className={styles.addCard}>
                      <span onClick={handleAddCard}>
                        {localeStore.t('components.business["payment-modal"]["add-new-card"]')}
                      </span>
                    </div>
                  )}
                </div>
                <Button
                  appearance="primaryOutlined"
                  onClick={setClose}
                  disabled={useLoadingBoolean.value}
                >
                  {localeStore.t("common.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  disabled={!stripe || !selectedCard}
                  loading={useLoadingBoolean.value}
                >
                  {localeStore.t("common.buttons.next")}
                </Button>
              </Form>
            )}
          </ElementsConsumer>
        </Elements>
      </Modal>
    );
  },
);

export default PaymentModal;
