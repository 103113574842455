import React from "react";
import { Button, SvgIcon, UseBooleanReturnType } from "@gemlightbox/core-kit";
import { styled } from "@mui/material/styles";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router-dom";
import { SettingTabNames, getSettingsTab } from "src/containers/settings";
import { useStores } from "src/hooks";

import upgradeBanner from "src/external-ts/assets/images/pro-user-update-caption.png";
import { IconButton } from "@mui/material";
import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import { config } from "src/config/environment";

import styles from "./pro-user-upgrade-modal.module.css";
import { pushSupportTicketAccountUpgradeRequestEvent } from "src/utils";

const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: "448px",
    width: "448px",
    borderRadius: "16px",
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
  "& .MuiDialogContent-root": {
    padding: "0 24px",
  },
}));

type ProUserUpgradeModalProps = {
  content: string;
  visible: UseBooleanReturnType;
  onClose: VoidFunction;
};

export const ProUserUpgradeModal: React.FC<ProUserUpgradeModalProps> = ({
  visible,
  onClose,
  content,
}) => {
  const navigate = useNavigate();
  const { localeStore, userStore } = useStores();

  const handleUpgrade = async () => {
    if (window.$platform.isCompact) {
      await pushSupportTicketAccountUpgradeRequestEvent("ai_features_limit");
      userStore.parentPostMessageRedirect(`${config.gemhubUrl}/profile/subscriptions`);
    } else {
      navigate(getSettingsTab(SettingTabNames.subscriptions)?.item.path as string);
    }
    onClose();
  };

  return (
    <StyledDialog open={visible.value} onClose={onClose} maxWidth="xs">
      <DialogTitle className={styles.dialogHeader}>
        <img src={upgradeBanner} className={styles.banner} alt="banner" />
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#363E5C",
          }}
        >
          <SvgIcon icon={CrossSVG} size={16} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className={styles.contentText}>{content}</div>
        <div className={styles.contentSubText}>
          {localeStore.t('common["pro-user"]["sub-upgrade-text"]')}
        </div>
      </DialogContent>

      <DialogActions
        sx={{
          flexDirection: "column",
          marginTop: "34px",
          padding: "0 24px 12px",
        }}
      >
        <Button className={styles.submitBtn} onClick={handleUpgrade}>
          {localeStore.t("common.buttons.upgrade")}
        </Button>
        <Button appearance="secondaryOutlined" className={styles.cancelBtn} onClick={onClose}>
          {localeStore.t("common.buttons.cancel")}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
