import { createRequest } from "urql";
import { graphqlClient } from "src/api";
import { GoogleTagSupportedEvents, pushDataLayerEvent } from "./index";
import { USER_UPGRADE_ACCOUNT } from "src/api/graphql-api/user/user.gql";

export type UpgradeSourceEventPayload =
  | "manual"
  | "cloud_storage_full"
  | "ai_features_limit"
  | "custom_attributes"
  | "catalog_limit"
  | "shared_link_password"
  | "seats_limit"
  | "app_integration"
  | "model_image"
  | "image_resize"
  | "custom_domain"
  | "embed_video"
  | "batch_ai"
  | "printed_catalog"
  | "premium_shared_settings"
  | "customers"
  | "other";

export const pushSupportTicketAccountUpgradeRequestEvent = async (
  payload: UpgradeSourceEventPayload,
) => {
  try {
    const request = createRequest(USER_UPGRADE_ACCOUNT, {}); // 假设没有变量，如果有，请替换 {}
    const result = (await graphqlClient.executeMutation(request).toPromise()) as any;
    const isError = Boolean(result?.error || result?.errors);
    let event: GoogleTagSupportedEvents;
    let event_params;
    if (isError) {
      event = "gemhub:support_ticket:account_upgrade_request:error";
      event_params = {
        source: payload,
        error_message: result ? JSON.stringify(result) : "null",
      };
    } else {
      event = "gemhub:support_ticket:account_upgrade_request:success";
      event_params = { source: payload };
    }
    pushDataLayerEvent({
      event,
      event_params,
    });
  } catch (error) {
    pushDataLayerEvent({
      event: "gemhub:support_ticket:account_upgrade_request:error",
      event_params: {
        source: payload,
        error_message: error ? JSON.stringify(error) : "null",
      },
    });
  }
};
