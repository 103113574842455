import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { SvgIcon, Image, Typography, clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { getStaticUrl, proFeatureTooltip } from "src/utils";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { augmentedRealityBlockSubId } from "./augmented-reality-block.constants";
import { AugmentedRealityType } from "./augmented-reality-block.types";

import styles from "./augmented-reality-block.module.css";
import { productDefaultTypesNames } from "src/models";

export type VirtualJewelryItemProps = {
  item: AugmentedRealityType;
};

export const AugmentedRealityItem: React.FC<VirtualJewelryItemProps> = observer(
  ({ item }: VirtualJewelryItemProps) => {
    const { type, jewelryIcon, jewelryThumbnail } = item;

    const navigate = useNavigate();
    const location = useLocation();

    const containerRef = useRef<HTMLDivElement | null>(null);

    const { tooltipStore, localeStore } = useStores();

    const { canBlinks } = useLimits();

    const handleMouseOver = () => {
      if (canBlinks) {
        tooltipStore.open(
          <Typography size="extraSmall">
            {localeStore.t(
              `create["augmented-reality-item"]["ar-types"].${
                productDefaultTypesNames[type.toLocaleLowerCase()]
              }` as LocaleCodeTypes,
              type,
            )}
          </Typography>,
          {
            target: containerRef,
            className: styles.augmentedRealityTooltip,
            appearance: "secondary",
            position: "bottom",
            withAngle: true,
          },
        );

        return;
      }

      proFeatureTooltip(augmentedRealityBlockSubId, "model_image");
    };

    const handleMouseLeave = () => {
      if (!canBlinks) return;
      tooltipStore.close();
    };

    const handleClick = () => {
      if (!canBlinks) return;
      tooltipStore.close();
      navigate(location.pathname + "/augmented-reality/" + type);
    };

    return (
      <div
        className={clsx(styles.augmentedRealityItem, { [styles.canHover]: canBlinks })}
        ref={containerRef}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        data-name={`${type.toLocaleLowerCase()}-model-image`}
      >
        <SvgIcon
          className={styles.augmentedRealityIcon}
          icon={getStaticUrl(jewelryIcon)}
          size={32}
        />
        <Image
          className={styles.augmentedRealityThumbnail}
          src={getStaticUrl(jewelryThumbnail)}
          loading="eager"
        />
      </div>
    );
  },
);

export default AugmentedRealityItem;
