import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { proFeatureTooltip } from "src/utils";
import { editMediaStore } from "../edit-media.store";
import { NavbarItem } from "./navbar-item";

import { ReactComponent as AdjustmentsSVG } from "src/external-ts/assets/images/edit-media/palette-grey.svg";
import { ReactComponent as MeasurementsSVG } from "src/external-ts/assets/images/edit-media/ruler-grey.svg";
import { ReactComponent as ResizeSVG } from "src/external-ts/assets/images/edit-media/crop-grey.svg";
import { ReactComponent as BackgroundSVG } from "src/external-ts/assets/images/edit-media/background-icon.svg";
import { ReactComponent as InfoSVG } from "src/external-ts/assets/images/edit-media/info-icon.svg";
import styles from "./navbar.module.css";

export const Navbar: React.FC = observer(() => {
  const { localeStore, tooltipStore, subscriptionsStore } = useStores();
  const { currentTab, isBGRemoved, metaData } = editMediaStore;

  const proDisabled = !subscriptionsStore.isSubscribed;

  const handleAdjustmentsClick = () => editMediaStore.openAdjustmentsTab();

  const handleMeasurementsClick = () => editMediaStore.openMeasurementsTab();

  const handleResizeClick = () => editMediaStore.openResizeTab();
  const handleResizePointerEnter = (e: React.PointerEvent<HTMLButtonElement>) => {
    if (proDisabled) {
      proFeatureTooltip(e.currentTarget, "image_resize", { position: "bottomLeft" });
    }
  };
  const handleResizePointerLeave = () => {
    if (!proDisabled) tooltipStore.close();
  };

  const handleBackgroundClick = () => editMediaStore.openBackgroundTab();
  const handleBackgroundPointerEnter = (e: React.PointerEvent<HTMLButtonElement>) => {
    if (isBGRemoved || metaData.isAiRetouch || metaData.isAiBackgroundRemoval) return;

    tooltipStore.open(
      <Fragment>
        <Typography size="extraSmall">
          {localeStore.t('["edit-media"].navbar.background["bg-remove-first"]')}
        </Typography>
      </Fragment>,
      {
        appearance: "secondary",
        position: "bottomLeft",
        target: e.currentTarget,
        withAngle: true,
        onPointerLeave: () => tooltipStore.close(),
      },
    );
  };
  const handleBackgroundPointerLeave = () => {
    if (isBGRemoved) tooltipStore.close();
  };

  const handleMediaInfoClick = () => editMediaStore.openMediaInfoTab();

  return (
    <div className={styles.editMediaNavbar}>
      <NavbarItem
        title={localeStore.t('["edit-media"].navbar.adjustments')}
        icon={AdjustmentsSVG}
        onClick={handleAdjustmentsClick}
        selected={currentTab === "adjustments"}
      />
      <NavbarItem
        title={localeStore.t('["edit-media"].navbar.measurements')}
        icon={MeasurementsSVG}
        onClick={handleMeasurementsClick}
        selected={currentTab === "measurements"}
      />
      <NavbarItem
        title={localeStore.t('["edit-media"].navbar.resize')}
        icon={ResizeSVG}
        onClick={handleResizeClick}
        onPointerEnter={handleResizePointerEnter}
        onPointerLeave={handleResizePointerLeave}
        selected={currentTab === "resize"}
        disabled={proDisabled}
        proBadge
      />
      <NavbarItem
        title={localeStore.t('["edit-media"].navbar.background.title')}
        icon={BackgroundSVG}
        onClick={handleBackgroundClick}
        onPointerEnter={handleBackgroundPointerEnter}
        onPointerLeave={handleBackgroundPointerLeave}
        selected={currentTab === "background"}
        disabled={!isBGRemoved}
      />
      <NavbarItem
        title={localeStore.t('media["media-info"].info')}
        icon={InfoSVG}
        onClick={handleMediaInfoClick}
        selected={currentTab === "media-info"}
      />
    </div>
  );
});

export default Navbar;
