import React from "react";
import { observer } from "mobx-react-lite";
import { Typography, Button, clsx } from "@gemlightbox/core-kit";

import { AttributesIdMap } from "src/store";
import { useStores } from "src/hooks";
import { AttributesList } from "./attributes-list";
import { columnsToProducts, filterEmptyColumns, validateProducts } from "../products-import.utils";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./attributes-panel.module.css";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { proFeatureTooltip } from "src/utils";

export interface AttributesPanelProps {
  connection: any; // TODO: replace once websocket is refactored to typescript
}

export const AttributesPanel: React.FC<AttributesPanelProps> = observer(({ connection }) => {
  const { attributesStore, productsImportStore, modalsStore, localeStore } = useStores();
  const { canCustomAttributes } = useLimits();

  const handleBeforeImport = async () => {
    const filteredColumns = filterEmptyColumns(productsImportStore.columns);
    const parsedProducts = columnsToProducts(filteredColumns, attributesStore.attributes);
    const [errors, validProducts] = validateProducts(parsedProducts, attributesStore.attributes);

    modalsStore.open("FinishImportModal", {
      totalProducts: parsedProducts,
      validProducts,
      errors,
      connection,
    });
  };

  const handleCreateAttribute = () =>
    modalsStore.open("CreateAttributeSidebar", {
      onCreate: (name: string) =>
        productsImportStore.updateAttributes(name, attributesStore.attributes),
    });

  const checkIfCanImport = () => {
    const withMandatory = productsImportStore.attributes.mandatory.length === 0;
    const withRequired = productsImportStore.attributes.required.length === 0;

    return withMandatory && withRequired;
  };

  const handlePointerEnter = (e: React.PointerEvent<HTMLButtonElement>) => {
    if (canCustomAttributes) return;
    proFeatureTooltip(e.currentTarget, "custom_attributes", {
      position: "bottomRight",
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Typography size="small600" color="textSecondary">
            {localeStore.t('["products-import"]["attributes-panel"].title')}
          </Typography>
          <Button
            disabled={!canCustomAttributes}
            onPointerEnter={handlePointerEnter}
            appearance="primaryOutlined"
            onClick={handleCreateAttribute}
          >
            {localeStore.t('["products-import"]["attributes-panel"].buttons.create')}
          </Button>
        </div>

        <div className={clsx(globalStyles.addScrollStyles, styles.list)}>
          <AttributesList
            title={localeStore.t(
              '["products-import"]["attributes-panel"]["list-titles"].mandatory',
            )}
            id={AttributesIdMap.mandatory}
            attributes={productsImportStore.attributes.mandatory}
          />
          <AttributesList
            title={localeStore.t('["products-import"]["attributes-panel"]["list-titles"].required')}
            id={AttributesIdMap.required}
            attributes={productsImportStore.attributes.required}
          />
          <AttributesList
            title={localeStore.t('["products-import"]["attributes-panel"]["list-titles"].optional')}
            id={AttributesIdMap.optional}
            attributes={productsImportStore.attributes.optional}
          />
        </div>
      </div>

      <Button disabled={!checkIfCanImport()} onClick={handleBeforeImport}>
        {localeStore.t('["products-import"]["attributes-panel"].buttons.import')}
      </Button>
    </div>
  );
});
