import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import {
  ExpandableSidebar,
  Search,
  Button,
  MaybeArray,
  toArrayOptions,
  isEqualBy,
  ObjectType,
  useDebounce,
  useDidUpdate,
  Typography,
  NotificationText,
  NotificationTextSecondary,
  useMediaBreakpoints,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { proFeatureTooltip } from "src/utils";
import { AttributeModel, AttributeRequestModel } from "src/models";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { AttributesList } from "./attributes-list";

import styles from "./manage-attributes-sidebar.module.css";

export interface ManageAttributesSidebarProps {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
  options: ObjectType;
}

export const ManageAttributesSidebar: React.FC<ManageAttributesSidebarProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const { debounce } = useDebounce(150);

    const { isMobileMedia } = useMediaBreakpoints();

    const { attributesStore, modalsStore, localeStore, notificationStore } = useStores();

    const { canCustomAttributes } = useLimits();

    const initialAttributes = attributesStore.galleryAttributes;
    const [currentAttributes, setCurrentAttributes] = useState(() => {
      return toJS(attributesStore.galleryAttributes);
    });
    const [editedIds, setEditedIds] = useState<number[]>([]);

    useDidUpdate(() => {
      setCurrentAttributes(toJS(attributesStore.galleryAttributes));
    }, [attributesStore.attributes.length]);

    const handleSearch = (search: string) => {
      const filters = { ...attributesStore.filters, search };

      attributesStore.setFilters(filters);
      debounce(() => attributesStore.loadAttributesList(filters));
    };

    const checkForChanges = (attributes: MaybeArray<AttributeModel>) => {
      let resultIds = [...editedIds];

      toArrayOptions(attributes).forEach((data) => {
        const initialAttribute = initialAttributes.find(
          (item: AttributeModel) => item.id === data.id,
        );

        if (initialAttribute) {
          const equal = isEqualBy<AttributeModel, AttributeModel>(initialAttribute, data, [
            "name",
            "required",
            "suffix",
            "prefix",
            "isPublic",
            "order_gallery",
          ]);
          const editedIndex = resultIds.findIndex((id) => id === data.id);

          if (equal && editedIndex !== -1) {
            resultIds.splice(editedIndex, 1);
          }

          if (!equal) {
            const uniqueIds = new Set([...resultIds, data.id]);
            resultIds = [...uniqueIds];
          }
        }
      });

      setEditedIds(resultIds);
    };

    const handleEditDefaultAttribute = (data: AttributeModel) => {
      const result = currentAttributes.map((item: AttributeModel) => {
        if (item.id === data.id) return data;
        return item;
      });

      setCurrentAttributes(result);
      checkForChanges(data);
    };

    const handleReorderAttributes = (data: AttributeModel[]) => {
      setCurrentAttributes(data);
      checkForChanges(data);
    };

    const handleUpdateAttributes = async () => {
      const attributesToUpdate: Record<number, AttributeRequestModel> = editedIds.reduce(
        (acc, id) => {
          const index = currentAttributes.findIndex((attr) => attr.id === id);

          if (index !== -1) {
            acc[id] = attributesStore.getAttributeRequestPayload(currentAttributes[index]);
          }

          return acc;
        },
        {} as Record<number, AttributeRequestModel>,
      );

      const promises: Promise<any>[] = [];

      try {
        Object.entries(attributesToUpdate).forEach(([id, attribute]) => {
          promises.push(attributesStore.updateAttribute(+id, attribute));
        });

        await Promise.all(promises);

        notificationStore.open({
          title: localeStore.t('products.modals["manage-attributes-sidebar"]["success-message"]'),
          confirmText: localeStore.t(
            'products.modals["manage-attributes-sidebar"]["delete-modal"]["ok-text"]',
          ),
          cancelText: "",
          confirmAppearance: "primary",
          onlyConfirm: true,
        });

        setClose();
      } catch (e) {
        console.error(e);
        setCurrentAttributes(initialAttributes);
      } finally {
        setEditedIds([]);
      }
    };

    const handleCreateAttribute = (data?: AttributeModel) => {
      setClose();
      modalsStore.open("CreateAttributeSidebar", { attribute: data });
    };

    const handleEditAttribute = (data?: AttributeModel) => {
      notificationStore.open({
        title: localeStore.t('products.modals["manage-attributes-sidebar"]["edit-modal"].title'),
        message: (
          <>
            <NotificationTextSecondary>
              {localeStore.t(
                'products.modals["manage-attributes-sidebar"]["edit-modal"].description',
              )}
            </NotificationTextSecondary>
            <NotificationText>
              {localeStore.t('products.modals["manage-attributes-sidebar"]["edit-modal"].strong')}
            </NotificationText>
          </>
        ),
        confirmText: localeStore.t(
          'products.modals["manage-attributes-sidebar"]["edit-modal"]["ok-text"]',
        ),
        cancelText: localeStore.t("common.buttons.cancel"),
        confirmAppearance: "secondary",
        onOk: () => {
          setClose();
          modalsStore.open("CreateAttributeSidebar", { attribute: data });
        },
      });
    };

    const handleDeleteAttribute = (data: AttributeModel) => {
      notificationStore.open({
        title: localeStore.t('products.modals["manage-attributes-sidebar"]["delete-modal"].title'),
        message: (
          <>
            <NotificationTextSecondary>
              {localeStore.t(
                'products.modals["manage-attributes-sidebar"]["delete-modal"].description',
              )}
            </NotificationTextSecondary>
            <NotificationText>
              {localeStore.t('products.modals["manage-attributes-sidebar"]["delete-modal"].strong')}
            </NotificationText>
          </>
        ),
        confirmText: localeStore.t(
          'products.modals["manage-attributes-sidebar"]["delete-modal"]["ok-text"]',
        ),
        cancelText: localeStore.t("common.buttons.cancel"),
        confirmAppearance: "secondary",
        onOk: async () => attributesStore.deleteAttribute(data.id),
      });
    };

    const handlePointerEnter = (e: React.PointerEvent<HTMLButtonElement>) => {
      if (canCustomAttributes) return;
      proFeatureTooltip(e.currentTarget, "custom_attributes", {
        position: "bottomRight",
        onUpgradeClick: setClose,
      });
    };

    return (
      <ExpandableSidebar
        data-cy="manage-attributes-sidebar"
        title={localeStore.t('products.modals["manage-attributes-sidebar"].title')}
        icon="cross"
        iconPos={isMobileMedia ? "right" : "outside"}
        sidebarHeaderClassName={styles.sidebarHeader}
        sidebarContentClassName={styles.sidebarContent}
        sidebarFooterClassName={styles.sidebarFooter}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        header={
          <Button
            data-cy="create-new-attribute"
            onClick={() => handleCreateAttribute()}
            onPointerEnter={handlePointerEnter}
            disabled={!canCustomAttributes}
          >
            {localeStore.t('products.modals["manage-attributes-sidebar"].buttons.create')}
          </Button>
        }
        footer={
          <>
            <Button
              data-cy="cancel-button"
              className={styles.button}
              appearance="tertiaryOutlined"
              onClick={setClose}
            >
              {localeStore.t('products.modals["manage-attributes-sidebar"].buttons.cancel')}
            </Button>
            <Button
              data-cy="done-button"
              className={styles.button}
              disabled={editedIds.length === 0}
              loading={attributesStore.loading}
              onClick={handleUpdateAttributes}
            >
              {localeStore.t('products.modals["manage-attributes-sidebar"].buttons.done')}
            </Button>
          </>
        }
      >
        <Typography className={styles.instruction} size="extraSmall" color="textTertiary">
          {localeStore.t('products.modals["manage-attributes-sidebar"].instruction')}
        </Typography>
        <Search
          data-cy="search-field"
          searchValue={attributesStore.filters.search}
          placeholder={localeStore.t(
            'products.modals["manage-attributes-sidebar"]["search-placeholder"]',
          )}
          setSearch={handleSearch}
        />
        <AttributesList
          attributes={currentAttributes}
          onReorder={handleReorderAttributes}
          onDefaultEdit={handleEditDefaultAttribute}
          onCustomEdit={handleEditAttribute}
          onCustomDelete={handleDeleteAttribute}
        />
      </ExpandableSidebar>
    );
  },
);
