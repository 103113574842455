import React, { useRef, useState } from "react";
import {
  useDebounce,
  useDidMount,
  Nullable,
  SvgIcon,
  clsx,
  Button,
  useDidUpdate,
} from "@gemlightbox/core-kit";
import { AspectRatiosType } from "../camera.types";

import { ReactComponent as One2OneSVG } from "src/external-ts/assets/images/camera/1_1-icon.svg";
import { ReactComponent as Nine2SixteenSVG } from "src/external-ts/assets/images/camera/9_16-icon.svg";
import { ReactComponent as Four2FiveSVG } from "src/external-ts/assets/images/camera/4_5-icon.svg";
import { ReactComponent as Sixteen2NineSVG } from "src/external-ts/assets/images/camera/16_9-icon.svg";
import { ReactComponent as Four2ThreeSVG } from "src/external-ts/assets/images/camera/4_3-icon.svg";
import { ReactComponent as OnePointNineOne2OneSVG } from "src/external-ts/assets/images/camera/1.91_1-icon.svg";
import { ReactComponent as PageArrowSVG } from "src/external-ts/assets/images/camera/page-arrow-icon.svg";
import styles from "./resolution-select.module.css";
import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import { useStores } from "src/hooks";

const resolutionData = [
  { name: AspectRatiosType.One2One, src: One2OneSVG },
  { name: AspectRatiosType.Four2Three, src: Four2ThreeSVG },
  { name: AspectRatiosType.Sixteen2Nine, src: Sixteen2NineSVG },
  { name: AspectRatiosType.Four2Five, src: Four2FiveSVG },
  { name: AspectRatiosType.Nine2Sixteen, src: Nine2SixteenSVG },
  { name: AspectRatiosType.OnePointNineOne2One, src: OnePointNineOne2OneSVG },
];

interface ResolutionSelectProps {
  className?: string;
  selectedOption?: string | undefined;
  onChange?: (value: string) => void;
}

export const ResolutionSelect: React.FC<ResolutionSelectProps> = ({
  className,
  selectedOption,
  onChange,
}) => {
  const { localeStore } = useStores();
  const { debounce } = useDebounce(100);

  const handleChange = (option: string) => {
    if (selectedOption === option) return;

    debounce(() => onChange?.(option));
  };
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  const [clientWidth, setClientWidth] = useState<number>(0);
  const pesolutionSelectRef = useRef<HTMLDivElement>(null);
  const selectedItemRef = useRef<HTMLDivElement>(null);
  const canGoPrev = localeStore.isLTR
    ? scrollWidth > clientWidth && scrollLeft !== 0
    : scrollWidth > clientWidth && scrollLeft === 0;
  const canGoNext = localeStore.isLTR
    ? scrollWidth > clientWidth && scrollLeft === 0
    : scrollWidth > clientWidth && scrollLeft !== 0;

  const handleScroll = (left: number) => {
    if (pesolutionSelectRef.current) {
      pesolutionSelectRef.current.scrollTo({
        left,
        behavior: "smooth",
      });
    }
  };

  const scrollElementIntoViewIfNeeded = (element?: Nullable<HTMLDivElement>) => {
    if (!element) return;

    const parentElement = element.parentElement;
    if (!parentElement) return;

    // 获取元素和父元素的边界矩形
    const elementRect = element.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();

    // 检查是否被遮挡
    const isOverflowingTop = elementRect.top < parentRect.top;
    const isOverflowingBottom = elementRect.bottom > parentRect.bottom;
    const isOverflowingLeft = elementRect.left < parentRect.left;
    const isOverflowingRight = elementRect.right > parentRect.right;

    if (isOverflowingTop || isOverflowingBottom || isOverflowingLeft || isOverflowingRight) {
      // 计算水平和垂直滚动距离
      const scrollTop =
        elementRect.top -
        parentRect.top +
        parentElement.scrollTop -
        parentRect.height / 2 +
        elementRect.height / 2;
      const scrollLeft =
        elementRect.left -
        parentRect.left +
        parentElement.scrollLeft -
        parentRect.width / 2 +
        elementRect.width / 2;

      // 滚动父元素，使目标元素进入视口中央
      parentElement.scrollTo({
        top: scrollTop,
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

  useDidMount(() => {
    setScrollWidth(pesolutionSelectRef?.current?.scrollWidth || 0);
    setClientWidth(pesolutionSelectRef?.current?.clientWidth || 0);
    scrollElementIntoViewIfNeeded(selectedItemRef?.current);
  });

  useDidUpdate(() => {
    scrollElementIntoViewIfNeeded(selectedItemRef?.current);
  }, [selectedItemRef?.current, selectedOption]);

  return (
    <div className={clsx(styles.resolutionSelectWrapper, className)}>
      <div
        className={clsx(styles.resolutionSelectContainer, globalStyles.hideScrollBar)}
        ref={pesolutionSelectRef}
        onScroll={(e) => {
          setScrollLeft((e.target as HTMLDivElement).scrollLeft);
        }}
      >
        {resolutionData.map((item, index) => {
          const SvgComponent = item.src;
          return (
            <div
              key={index}
              className={clsx(
                styles.resolutionItem,
                selectedOption === item.name && styles.selected,
              )}
              ref={selectedOption === item.name ? selectedItemRef : undefined}
              onClick={() => handleChange(item.name)}
            >
              <SvgComponent />
            </div>
          );
        })}
      </div>
      {canGoPrev && (
        <div className={clsx(styles.pageArrowButtonWrapper, styles.leftPageArrowButtonWrapper)}>
          {localeStore.isLTR && <div className={styles.pageArrowGradient}></div>}
          <Button
            className={styles.pageArrowButton}
            appearance="unset"
            size="unset"
            onClick={() =>
              handleScroll(
                localeStore.isLTR ? 0 : -(pesolutionSelectRef?.current?.scrollWidth || 0),
              )
            }
          >
            <SvgIcon size={10} icon={PageArrowSVG} />
          </Button>
          {!localeStore.isLTR && <div className={styles.pageArrowGradient}></div>}
        </div>
      )}
      {canGoNext && (
        <div className={styles.pageArrowButtonWrapper}>
          {localeStore.isLTR && <div className={styles.pageArrowGradient}></div>}
          <Button
            className={styles.pageArrowButton}
            appearance="unset"
            size="unset"
            onClick={() =>
              handleScroll(localeStore.isLTR ? pesolutionSelectRef?.current?.scrollWidth || 0 : 0)
            }
          >
            <SvgIcon size={10} icon={PageArrowSVG} />
          </Button>
          {!localeStore.isLTR && <div className={styles.pageArrowGradient}></div>}
        </div>
      )}
    </div>
  );
};
