import { ObjectType } from "@gemlightbox/core-kit";

import { ENVIRONMENT } from "src/config/environment";
import { SupportedSubscriptionInterval } from "./subscriptions.types";

export const newPROPlanId =
  ENVIRONMENT === "production" ? "prod_KjAkukOM8r5cS2" : "prod_Kj5eyzEr2rjhEA";
export const proPlanId =
  ENVIRONMENT === "production" ? "prod_NM5Pf0xTE8VBYp" : "prod_NJXKCjsRalT9Jr";
export const proPlanIdV2 =
  ENVIRONMENT === "production" ? "prod_QLlV3XOUUEIRht" : "prod_QLlAXyFRNIYMtH";
export const proPlanMonthlyPriceId =
  ENVIRONMENT === "production"
    ? "price_1N14FsDEiMxyRd4Y7TW8csjX"
    : "price_1N14FsDEiMxyRd4Y7TW8csjX";
export const proActivatePlanMonthlyPriceId =
  ENVIRONMENT === "production"
    ? "price_1MYuFeDEiMxyRd4YBdVtJW58"
    : "price_1N80OlDEiMxyRd4YCLjKqYLB";
export const proPlanYearlyPriceId = "price_1MYuFeDEiMxyRd4YEUxVDVF9";

export const proV2PlanMonthlyPriceId =
  ENVIRONMENT === "production"
    ? "price_1QWwRZFn3pWOPFd75Nw5N58T"
    : "price_1QWwSyDEiMxyRd4YJJSAb5Ju";
export const proV2PlanYearlyPriceId = "price_1QWYtFDEiMxyRd4YI0Ml1KG4";

export const starterPlanId =
  ENVIRONMENT === "production" ? "prod_MYHhpBXZzWTVLp" : "prod_MNOcAWBiOI9vtd";
export const starterPlanMonthlyPriceId = "price_1LedowDEiMxyRd4YLYyBSv3F";
export const starterPlanYearlyPriceId = "price_1MCiIPDEiMxyRd4Y4vmhF5mk";

export const oldStarterPlanId =
  ENVIRONMENT === "production" ? "prod_JdG2m6GlJJt8uf" : "prod_JQxnSzKs0wkIEe";

export const additionalSubGbAddonId =
  ENVIRONMENT === "production" ? "prod_KjBEzof7ADEHcu" : "prod_Kf7YXCg5L6Ed0o";
export const additionalSubGbAddonMonthlyPriceId = "price_1JznIuDEiMxyRd4Y0XgIIyyS";
export const additionalSubGbAddonYearlyPriceId = "price_1K3ezhDEiMxyRd4YjJwMhFoJ";

export const proV2SubscriptionsTypePriceMap: ObjectType<string, SupportedSubscriptionInterval> = {
  monthly: proV2PlanMonthlyPriceId,
  yearly: proV2PlanYearlyPriceId,
  lifetime: proV2PlanYearlyPriceId,
};

export const proSubscriptionsTypePriceMap: ObjectType<string, SupportedSubscriptionInterval> = {
  monthly: proPlanMonthlyPriceId,
  yearly: proPlanYearlyPriceId,
  lifetime: proPlanYearlyPriceId,
};

export const starterSubscriptionsTypePriceMap: ObjectType<string, SupportedSubscriptionInterval> = {
  monthly: starterPlanMonthlyPriceId,
  yearly: starterPlanYearlyPriceId,
  lifetime: starterPlanYearlyPriceId,
};

export const additionalSubGbPriceMap: ObjectType<string, SupportedSubscriptionInterval> = {
  monthly: additionalSubGbAddonMonthlyPriceId,
  yearly: additionalSubGbAddonYearlyPriceId,
  lifetime: additionalSubGbAddonYearlyPriceId,
};

export const oppositeSubscriptionsMap: ObjectType<
  SupportedSubscriptionInterval,
  SupportedSubscriptionInterval
> = {
  monthly: "yearly",
  yearly: "monthly",
  lifetime: "lifetime",
};

export const subscriptionsData = {
  storage: [{ name: "Subaccounts" }, { name: "Storage" }],
  features: [
    {
      name: "Catalogs",
      freePlanAmount: "3 catalogs",
      starterPlanAmount: "10 catalogs",
      proPlanAmount: "Unlimited",
      key: "foo-bar",
    },
    {
      name: "AI Background Removal",
      freePlanAmount: "10/month",
      starterPlanAmount: "Unlimited",
      proPlanAmount: "Unlimited",
      key: "foo-bar",
    },
    {
      name: "AI Retouching",
      freePlanAmount: "10/month",
      starterPlanAmount: "Unlimited",
      proPlanAmount: "Unlimited",
      key: "foo-bar",
    },
    {
      name: "AI Dust Removal",
      freePlanAmount: "10/month",
      starterPlanAmount: "Unlimited",
      proPlanAmount: "Unlimited",
      key: "foo-bar",
    },
    {
      name: "AI Description writing",
      freePlanAmount: "10/month",
      starterPlanAmount: "10/month",
      proPlanAmount: "Unlimited",
      key: "foo-bar",
    },
    {
      name: "Integrations",
      freePlanAmount: "-",
      starterPlanAmount: "-",
      proPlanAmount: "Shopify",
      key: "foo-bar",
    },
  ],
} as const;
