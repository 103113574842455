import React, { Fragment } from "react";
import { TooltipTarget, Typography, TooltipProps, Link, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";

import styles from "./pro-feature-tooltip.module.css";
import { config } from "src/config/environment";
import { ReactComponent as CrownSVG } from "src/external-ts/assets/images/crown-pro-plan2.svg";
import { pushSupportTicketAccountUpgradeRequestEvent, UpgradeSourceEventPayload } from "src/utils";

type ProFeatureTooltipOptions = {
  onUpgradeClick?: VoidFunction;
} & Partial<Pick<TooltipProps, "offsetX" | "offsetY" | "position">>;

export const proFeatureTooltip = (
  target: TooltipTarget,
  source: UpgradeSourceEventPayload,
  { position = "bottom", offsetX, offsetY, onUpgradeClick }: ProFeatureTooltipOptions = {},
  text?: string,
) => {
  const { tooltipStore, localeStore, userStore } = useStores();

  const handleClick = async () => {
    tooltipStore.close();
    await pushSupportTicketAccountUpgradeRequestEvent(source);
    onUpgradeClick?.();
    if (window.$platform.isCompact) {
      userStore.parentPostMessageRedirect(`${config.gemhubUrl}/profile/subscriptions`);
    } else {
      window.location.href = getSettingsTab(SettingTabNames.subscriptions)?.item.path as string;
    }
  };

  tooltipStore.open(
    <Fragment>
      <SvgIcon icon={CrownSVG} size={18} />
      <Typography size="extraSmall" data-cy="pro-feature-tooltip">
        {text || localeStore.t('common["pro-feature"].text')}{" "}
      </Typography>
      {!userStore.isAppStoreDemoAccount && (
        <span className={styles.upgradeButton} onClick={handleClick} data-cy="upgrade-button">
          {localeStore.t("common.buttons.upgrade")}
        </span>
      )}
    </Fragment>,
    {
      className: styles.proFeatureTooltip,
      target,
      appearance: "unset",
      position,
      offsetX,
      offsetY,
      onPointerLeave: () => tooltipStore.close(),
    },
  );
};
